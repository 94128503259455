import React from "react"
import Layout from "../components/Layout/layout"
import TermCondition from "../components/Term&Condtion/Termcondition"

const TermConditionPage = (
  {
    pageContext
  }
) => {
  const { navBar, footer, termsAndConditions } = pageContext;
  return (
    <Layout navBar={navBar} footer={footer}>
      <TermCondition data={termsAndConditions} />
    </Layout>
  )
}
export default TermConditionPage
